import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token',
      'Access-Control-Allow-Origin': '*'
    })
};

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(private http: HttpClient) {

    }

    getDrawing(sentence: string) {
        return this.http.get<IDrawing>(environment.url + 'api/Shape/' + sentence, httpOptions);
    }
}

export interface IDrawing {
    Shape: IShape;
    Coordinates: ICoordinate[];
    Message: string;
}

export interface ICoordinate {
    X: number;
    Y: number;
}

export interface IShape {
    Radius: number;
    Radius1: number;
    Radius2: number;
    Length: number;
    Width: number;
    Height: number;
}
