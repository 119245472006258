import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiService, IDrawing, ICoordinate } from '../app/services/api.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Shape Generator';
  drawing: IDrawing;

  public fb  = new FormBuilder;
  public frmGroup: FormGroup;
  public points = "";
  public message = "";
  public width = document.body.clientWidth - 30;
  public height = 400;
  public radius1;
  public radius2;

  public list = [
    'Draw Rectangle with Height 120 and Width 200',
    'Draw Pentagon with Length 100',
    'Draw Hexagon with Length 100',
    'Draw Heptagon with Length 100',
    'Draw Octagon with Length 100',
    'Draw Isosceles Triangle with Width 100 Height 100',
    'Draw Equilateral Triangle with Length 100',
    'Draw Scalene Triangle with Width 100 Height 120 Angle 60',
    'Draw Parallelogram with Width 100 Height 120 Angle 60',
    'Draw Circle with Radius 150',
    'Draw Oval with Radius1 200 Radius2 150'
  ];

  constructor(private apiService: ApiService) {
  }

  ngOnInit() {
    this.frmGroup = this.fb.group({
      sentence: '',
    });

    this.frmGroup.setValue({sentence: this.list[0]});
    this.getDrawing();
  }

  getDrawing() {
    this.apiService.getDrawing(this.frmGroup.get('sentence').value).subscribe(drawing => {
      this.draw(drawing);
    });
  }

  draw(drawing: IDrawing) {
    this.points = "";
    this.message = drawing.Message;
    this.radius1 = 0;
    this.radius2 = 0;
    
    let sumX = 0, sumY = 0;
    if(drawing.Coordinates) {
      drawing.Coordinates.forEach((c:ICoordinate) => {
        sumX += c.X;
        sumY += c.Y;
      });
      
      let midX = (this.width / 2) - ((sumX / drawing.Coordinates.length));
      let midY = (this.height / 2) - ((sumY / drawing.Coordinates.length));
      drawing.Coordinates.forEach((c:ICoordinate) => {
        this.points += (c.X + midX) + ',' + (c.Y + midY) + ' ';
      });
    }
    else if(drawing.Shape) {
      this.radius1 = drawing.Shape.Radius || drawing.Shape.Radius1;
      this.radius2 = drawing.Shape.Radius || drawing.Shape.Radius2;
    }
  }

  copyAndDraw(item: string) {
    this.frmGroup.setValue({ 'sentence': item })
    this.getDrawing();
  }
}
